<template>
  <div class="content">
    <div class="phone" @click="isShow">
      <div v-if="show">
        <div>点击拨打</div>
        <div>
          <a href="tel:0571-87073879">0571-87073879</a>
        </div>
      </div>
    </div>
    <div class="wx" @click="wx"></div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  components: {},
  name: '',
  data () {
    return {
      show: false
    }
  },

  methods: {
    isShow () {
      this.show = !this.show
      setTimeout(() => {
        this.show = false
      }, 2000)
    },

    wx () {
      ImagePreview({
        images: [
          require('../assets/assets/WechatIMG336.png')
        ],
        closeable: true
      })
    }
  }
}
</script>

<style scoped lang="less">
.content{
  position: fixed;
  right: 20px;
  bottom: 70px;
  height: 70px;
  width: 40px;
  >div{
    width: 40px;
    height: 40px;
  }
  .phone{
    position: relative;
    background: url('../assets/assets/phone.png') no-repeat;
    background-size: contain;
    >div{
      position: absolute;
      left: -140px;
      bottom: -20px;
      height: 46px;
      width: 130px;
      font-size: 14px;
      text-align: center;
      border: 1px solid #f3f3f3;
      border-radius: 12px;
      background: #fff;
      padding-top: 12px;
      box-shadow: 4px 4px 10px #c1c1c1;

    }
  }
  .wx{
    background: url('../assets/assets/wx.png') no-repeat;
    background-size: contain;
    margin-top: 20px;
  }
}

</style>
