import Vue from "vue";
import Router from "vue-router";
import Home from "../pages/home/index.vue";
import About from "../pages/about.vue";

Vue.use(Router);

const routes = [
  {
    path: '/m_homeindex',
        name: 'Mhomeindex',
        component: () => import('@/views/home/index.vue') 
      },
       //   // 智慧小区
      {
        path: '/m_residentialQuarters',
        name: 'MresidentialQuarters',
        component: () => import('../views/home/residentialQuarters.vue')
      },
         // 智慧小区详情
      {
        path: '/m_residentialQuartersDetail',
        name: 'MresidentialQuartersDetail',
        component: () =>
          import('../views/home/components/residentialQuartersDetail.vue')
      },
            // 智慧楼宇
      {
        path: '/m_building',
        name: 'Mbuilding',
        component: () => import('../views/home/building.vue')
      },
      // 智慧楼宇详情
      {
        path: '/m_buildingDetail',
        name: 'MbuildingDetail',
        component: () => import('../views/home/components/buildingDetail.vue')
      },
        // 智慧园区
      {
        path: '/m_park',
        name: 'Mpark',
        component: () => import('../views/home/park.vue')
      },
      // 智慧园区详情
      {
        path: '/m_parkDetail',
        name: 'MparkDetail',
        component: () => import('../views/home/components/parkDetail.vue')
      },
            // 智慧交通
      {
        path: '/m_traffic',
        name: 'Mtraffic',
        component: () => import('../views/home/traffic.vue')
      },
          //   // 数字乡村
      {
        path: '/m_rural',
        name: 'Mrural',
        component: () => import('../views/home/rural.vue')
      },
          
      // 智慧警务
      {
        path: '/m_fbi',
        name: 'Mfbi',
        component: () => import('../views/home/fbi.vue')
      },
      // 智慧警务详情
      {
        path: '/m_fbiDetail',
        name: 'MfbiDetail',
        component: () => import('../views/home/components/fbiDetail.vue')
      },
           // 智慧城市
      {
        path: '/m_city',
        name: 'Mcity',
        component: () => import('../views/home/city.vue')
      },
      // 智慧城市详情
      {
        path: '/m_cityDetail',
        name: 'McityDetail',
        component: () => import('../views/home/components/cityDetail.vue')
      },
            // 政务大数据
      {
        path: '/m_government',
        name: 'Mgovernment',
        component: () => import('../views/home/government.vue')
      },
      // 水务
      {
        path: '/m_waterAffairs',
        name: 'MwaterAffairs',
        component: () => import('../views/home/waterAffairs.vue')
      },
         {
        path: '/m_wisdomWater',
        name: 'MwisdomWater',
        component: () => import('../views/home/wisdomWater.vue')
      },
      {
        path: '/m_monitor',
        name: 'Mmonitor',
        component: () => import('../views/home/monitor.vue')
      },
          {
        path: '/m_sewage',
        name: 'Msewage',
        component: () => import('../views/home/sewage.vue')
      },
      // 关于我们
      {
        path: '/m_home',
        name: 'm_home',
        component: () => import('../views/home/home.vue')
      },
  


    //   // 基层治理
    //   {
    //     path: '/basic',
    //     name: 'basic',
    //     component: () => import('../views/home/basic.vue')
    //   },

    //   // 基层治理详情
    //   {
    //     path: '/basicDetail',
    //     name: 'basicDetail',
    //     component: () => import('../views/home/components/basicDetail.vue')
    //   },

    //   // 公司详情
    //   {
    //     path: '/home',
    //     name: 'home',
    //     component: () => import('../views/home/home.vue')
    //   },

 
  {
    path: "/home",
    name: "home",
    component: Home,
  },

  {
    path: "/about",
    name: "关于我们",
    component: About,
  },
  {
    path: "/city",
    name: "智慧城市",
    component: () => import("../pages/products/city.vue"),
  },
  {
    path: "/building",
    name: "智慧楼宇",
    component: () => import("../pages/products/building.vue"),
  },
  {
    path: "/park",
    // name: '智慧园区',
    name: "智慧交通",
    component: () => import("../pages/products/park.vue"),
  },
  {
    path: "/parkas",
    name: '智慧园区',
    // name: "智慧交通",
    component: () => import("../pages/products/parkas.vue"),
  },
  {
    path: "/fbi",
    name: "智慧警务",
    component: () => import("../pages/products/fbi.vue"),
  },
  {
    path: "/residentialQuarters",
    name: "智慧小区",
    component: () => import("../pages/products/residentialQuarters.vue"),
  },
  {
    path: "/rural",
    name: "数字乡村",
    component: () => import("../pages/products/rural.vue"),
  },
  {
    path: "/governmentBigData",
    name: "政务大数据",
    component: () => import("../pages/products/governmentBigData.vue"),
  },
  {
    path: "/theBasicLevel",
    name: "基层治理",
    component: () => import("../pages/products/theBasicLevel.vue"),
  },
  {
    path: "/waterAffairs",
    name: "智慧水务",
    component: () => import("../pages/products/waterAffairs.vue"),
  },
  // 基层治理二级页面
  {
    path: "/unified",
    name: "基层治理 统一指挥中心",
    component: () =>
      import("../pages/products/components/theBasicLevel/unified.vue"),
  },
  {
    path: "/grid",
    name: "基层治理 网格化管理",
    component: () =>
      import("../pages/products/components/theBasicLevel/grid.vue"),
  },
  {
    path: "/unifiedCommand",
    name: "基层治理 数据统一掌控",
    component: () =>
      import("../pages/products/components/theBasicLevel/unifiedCommand.vue"),
  },
  {
    path: "/Security",
    name: "基层治理 安防管控",
    component: () =>
      import("../pages/products/components/theBasicLevel/Security.vue"),
  },
  {
    path: "/visualNetworking",
    name: "基层治理 视联网应用",
    component: () =>
      import("../pages/products/components/theBasicLevel/visualNetworking.vue"),
  },
  // 智慧小区二级页面
  {
    path: "/visualization",
    name: "智慧小区 可视化大屏",
    component: () =>
      import(
        "../pages/products/components/residentialQuarters/visualization.vue"
      ),
  },
  {
    path: "/InAndOut",
    name: "智慧小区 出入系统分析",
    component: () =>
      import("../pages/products/components/residentialQuarters/InAndOut.vue"),
  },
  {
    path: "/video",
    name: "智慧小区 视频信息管理",
    component: () =>
      import("../pages/products/components/residentialQuarters/video.vue"),
  },
  {
    path: "/StudyAndJudge",
    name: "智慧小区 研判分析",
    component: () =>
      import(
        "../pages/products/components/residentialQuarters/StudyAndJudge.vue"
      ),
  },
  // 智慧楼宇二级页面
  {
    path: "/data",
    name: "智慧楼宇 数据采集",
    component: () => import("../pages/products/components/building/data.vue"),
  },
  {
    path: "/energyConsumption",
    name: "智慧楼宇 能耗分析",
    component: () =>
      import("../pages/products/components/building/energyConsumption.vue"),
  },
  {
    path: "/callPolice",
    name: "智慧楼宇 报警管理",
    component: () =>
      import("../pages/products/components/building/callPolice.vue"),
  },
  {
    path: "/equipment",
    name: "智慧楼宇 设备管理",
    component: () =>
      import("../pages/products/components/building/equipment.vue"),
  },
  // 智慧园区二级页面
  {
    path: "/CloudServices",
    name: "智慧园区 云服务平台",
    component: () =>
      import("../pages/products/components/park/CloudServices.vue"),
  },
  {
    path: "/consumptionManagement",
    name: "智慧园区 能耗管理系统",
    component: () =>
      import("../pages/products/components/park/consumptionManagement.vue"),
  },
  {
    path: "/Stereosynthesis",
    name: "智慧园区 立体综合安防系统",
    component: () =>
      import("../pages/products/components/park/Stereosynthesis.vue"),
  },
  {
    path: "/assetManagement",
    name: "智慧园区 资产管理系统",
    component: () =>
      import("../pages/products/components/park/assetManagement.vue"),
  },
  // 智慧警务二级页面
  {
    path: "/CloudMonitoring",
    name: "智慧警务 云监控",
    component: () =>
      import("../pages/products/components/fbi/CloudMonitoring.vue"),
  },
  {
    path: "/dispatch",
    name: "智慧警务 指挥调度",
    component: () => import("../pages/products/components/fbi/dispatch.vue"),
  },
  {
    path: "/nineSmallPlaces",
    name: "智慧警务 九小场所管理",
    component: () =>
      import("../pages/products/components/fbi/nineSmallPlaces.vue"),
  },
  {
    path: "/DataFusion",
    name: "智慧警务 数据融合与共享",
    component: () => import("../pages/products/components/fbi/DataFusion.vue"),
  },
  // 数字乡村二级页面
  {
    path: "/Agriculture",
    name: "智慧警务 云监控",
    component: () =>
      import("../pages/products/components/rural/Agriculture.vue"),
  },
  {
    path: "/partyBuilding",
    name: "智慧警务 指挥调度",
    component: () =>
      import("../pages/products/components/rural/partyBuilding.vue"),
  },
  {
    path: "/villageAffairs",
    name: "智慧警务 九小场所管理",
    component: () =>
      import("../pages/products/components/rural/villageAffairs.vue"),
  },
  {
    path: "/PovertyAlleviation",
    name: "智慧警务 数据融合与共享",
    component: () =>
      import("../pages/products/components/rural/PovertyAlleviation.vue"),
  },
  // 政务大数据二级页面
  {
    path: "/livelihood",
    name: "政务大数据 民生服务",
    component: () =>
      import("../pages/products/components/governmentBigData/livelihood.vue"),
  },
  {
    path: "/cityManagement",
    name: "政务大数据 城市综合管理",
    component: () =>
      import("../pages/products/components/governmentBigData/city.vue"),
  },
  {
    path: "/Examine",
    name: "政务大数据 审批服务",
    component: () =>
      import("../pages/products/components/governmentBigData/Examine.vue"),
  },
  {
    path: "/bigData",
    name: "政务大数据 大数据分析",
    component: () =>
      import("../pages/products/components/governmentBigData/bigData.vue"),
  },
  // 智慧城市二级页面
  {
    path: "/wisdom",
    name: "智慧城市 智慧城市数据中心",
    component: () => import("../pages/products/components/city/wisdom.vue"),
  },
  {
    path: "/spaceTime",
    name: "智慧城市 时空大数据与云平台",
    component: () => import("../pages/products/components/city/spaceTime.vue"),
  },
  {
    path: "/command",
    name: "智慧城市 智慧城市运行指挥中心",
    component: () => import("../pages/products/components/city/command.vue"),
  },
  {
    path: "/app",
    name: "智慧城市 智慧城市城市通APP",
    component: () => import("../pages/products/components/city/app.vue"),
  },
  {
    path: "/safetys",
    name: "智慧城市 平安城市",
    component: () => import("../pages/products/components/city/safetys.vue"),
  },
];
export default new Router({
  routes,
  //页面跳转到首页
  scrollBehavior(to, from, saveTop) {
 
 
    if (saveTop) {
      return saveTop;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
