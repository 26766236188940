<template>
  <div class="const">
    <div class="box">
      <div class="womin">
        <h2 style="color: #333">关于沃赛</h2>
        <div style="text-indent: 2em">
          杭州沃赛科技有限公司成立于2014年，座落于杭州西湖区，毗邻浙江大学。公司创始股东们具备丰富的行业经验和资源，研发团队由一批互联网精英所组成，依托浙等重点高校和科研机构，拥有广阔的技术前景和人才优势。沃赛科技始终以客户需求和技术驱动应用创新为导向，专注于智慧城市业务应用领域的软件平台开发，利用AI和大数据等技术，长期为政府和IT集成客户提供解决方案和技术服务，全心致力于数据治理及应用开发与交付。
        </div>
      </div>
      <div class="me">
        <img src="../assets/image/me.png" alt="" />
      </div>
    </div>
    <div class="box">
      <div class="contact">
        <h2 style="color: #333">联系我们</h2>
        <!-- <p><span>QQ：</span>2374248</p> -->
        <p><span>邮箱：</span>WS@wosaitech.com</p>
        <p><span>联系电话：0571-87073809</span></p>
        <p>
          <span>地址：</span>浙江省杭州市西湖区萍水西街80号优盘时代大厦
          <i class="mapincon"></i>
        </p>
      </div>
      <div class="map">
        <el-amap
          class="amap-box"
          :vid="'amap-vue'"
          :center="center"
          :zoom="zoom"
        >
          <el-amap-marker :icon="iconImg"></el-amap-marker>
        </el-amap>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      center: [120.100659, 30.300959], //中心坐标
      zoom: 17, //放大比例
      iconImg: require("@/assets/image/mapicon.png"), //图片路径
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script> 

<style scoped lang="scss">
.const {
  width: 100%;
  padding: 20px 160px;
  box-sizing: border-box;
  .box {
    width: 100%;
    height: 350px;
    display: flex;
    margin-top: 20px;
    background-color: #fff;
    h2 {
      margin: 50px;
      margin-left: 0;
    }
    .womin {
      width: 50%;
      padding: 50px;
      font-size: 16px;
      color: #6b6b6b;
      box-sizing: border-box;
    }
    .me {
      width: 50%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .contact {
      width: 50%;
      padding: 50px;
      font-size: 16px;
      color: #6b6b6b;
      box-sizing: border-box;
      p {
        margin-bottom: 15px;
      }
      .mapincon {
        display: inline-block;
        width: 18px;
        height: 25px;
        background: url("../assets/image/mapicon.png");
        position: absolute;
        transform: translateY(-30%) translateX(30%);
      }
    }
    .map {
      width: 50%;
      height: 350px;
    }
  }
}
</style>