import { render, staticRenderFns } from "./tail.vue?vue&type=template&id=7b8c1ead&scoped=true&"
import script from "./tail.vue?vue&type=script&lang=js&"
export * from "./tail.vue?vue&type=script&lang=js&"
import style0 from "./tail.vue?vue&type=style&index=0&id=7b8c1ead&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8c1ead",
  null
  
)

export default component.exports