<template>
  <div class="const">
    <div class="top-box">
      <div class="text">
        <div>沃赛科技</div>
        <div>专注城市智慧化建设</div>
      </div>
      <div class="more" @click="goMore()">了解更多</div>
    </div>
    <div class="business">
      <div>核心业务</div>
    </div>
    <div class="coreBusiness">
      <div
        class="box"
        v-for="(item, index) in businessList"
        :key="index"
        @click="go(index)"
      >
        <img :src="item.img" alt="" />
        <!-- <div class="box-text">{{ item.text }}</div> -->
        <div class="box-title">{{ item.text }}</div>
        <div>
          <!-- <router-link :to="item.to" class="linkText"> 了解更多</router-link>
          <span class="box-icon"></span> -->
        </div>
      </div>
    </div>
    <div class="partner">
      <div class="partner-title">合作伙伴</div>
      <div class="img-box"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  props: {},
  components: {},
  data() {
    return {
      businessList: [
        {
          text: "智慧小区",
          img: require("@/assets/image/homeImg/img1.png"),
        },
        {
          text: "智慧楼宇",
          img: require("@/assets/image/homeImg/img3.png"),
        },

        {
          text: "智慧园区",
          img: require("@/assets/image/homeImg/img4.png"),
        },
        {
          text: "智慧交通",
          img: require("@/assets/image/homeImg/img10.png"),
        },
        {
          text: "智慧警务",
          img: require("@/assets/image/homeImg/img5.png"),
        },
        {
          text: "数字乡村",
          img: require("@/assets/image/homeImg/img6.png"),
        },
        {
          text: "智慧城市",
          img: require("@/assets/image/homeImg/img2.png"),
        },
        {
          text: "政务大数据",
          img: require("@/assets/image/homeImg/img7.png"),
        },
        // {
        //   text: "基层治理",
        //   img: require("../../assets/image/homeImg/img8.png"),
        // },
        {
          text: "智慧水务",
          img: require("../../assets/image/homeImg/bcd30b6d.png"),
        },
        // {
        //   text: "教育服务",
        //   img: require("@/assets/image/homeImg/img9.png"),
        // },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    goMore() {
      this.$router.push("/about");
    },
    go(item) {
      if (item == 0) {
        this.$router.push("/residentialQuarters");
      }
      if (item == 1) {
        this.$router.push("/building");
      }

      if (item == 2) {
        this.$router.push("/parkas");
      }
      // if (item == 2) {
      //   this.$router.push("/building");
      // }
      if (item == 3) {
        this.$router.push("/park");
      }
      if (item == 4) {
        this.$router.push("/fbi");
      }
      if (item == 5) {
        this.$router.push("/rural");
      }
      if (item == 6) {
        this.$router.push("/city");
      }
      if (item == 7) {
        this.$router.push("/governmentBigData");
      }
      if (item == 8) {
        // window.open("http://www.saixueba.com/");
        this.$router.push("/waterAffairs");
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.const {
  width: 100%;
  box-sizing: border-box;
  background: url("../../assets/image/homeImg/bg.png");
  // background-size: 100% 100%;
  .linkText {
    color: #1864e3;
    text-decoration: none;
  }
  .top-box {
    width: 100%;
    // height: 530px;
    height: 50vh;
    padding: 0 160px;
    box-sizing: border-box;
    font-size: 40px;
    color: #ffffff;
    background: url("../../assets/image/bg.png") no-repeat;
    background-size: 100%;
    overflow: hidden;
    .text {
      margin-top: 118px;
      div {
        margin: 13px 0;
      }
    }
    .more {
      width: 120px;
      height: 36px;
      font-size: 16px;
      text-align: center;
      line-height: 36px;
      margin-top: 40px;
      border: 1px solid #ffffff;
      border-radius: 20px;
      cursor: pointer;
    }
  }
  .business {
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding: 0 160px;
    font-size: 28px;
    color: #ffffff;
    font-weight: normal;
    background: #3651d8;
    box-sizing: border-box;
  }
  .coreBusiness {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 10%;
    text-align: center;

    .box {
      width: 32.6%;
      font-size: 16px;
      color: #1864e3;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        margin-bottom: 10px;
      }
      .box-title {
        width: 100%;
        position: absolute;
        bottom: 15px;
        left: 0;
        height: 60px;
        line-height: 60px;
        background: rgba(0, 0, 0, 0.5);
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .box:nth-child(2),
    .box:nth-child(5),
    .box:nth-child(8) {
      margin: 0 1%;
    }
  }
  .partner {
    width: 100%;
    padding: 50px 0;
    background-color: #fff;
    margin-bottom: 50px;
    .partner-title {
      width: 160px;
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
      margin: 0 auto;
    }
    .img-box {
      margin: 0 10%;
      height: 215px;
      background: url("../../assets/image/homeImg/icon.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
