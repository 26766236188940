<template>
  <div class="content">
    <div class="cooperation">
      <h2>商业合作</h2>
      <div>电话：</div>
      <div>0571-87073879</div>
      <div>邮箱：</div>
      <div>WS@wosaitech.com</div>
      <div>公司地址：</div>
      <div>浙江省杭州市西湖区优盘时代</div>
    </div>
    <div class="record">
      <div>Copyright © 2014-2022 杭州沃赛科技有限公司</div>
      <div>All Rights Reserved浙ICP备15001708号</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: '',
  data () {
    return {}
  },

  methods: {}
}
</script>

<style scoped lang="less">
.content{
width: 100%;
height:300px;
background: #FFFFFF;
opacity: 1;
border-radius: 0px;
margin-top: 10px;
padding-bottom: 10px;
.cooperation{
  height: 280px;
  border-bottom: 1px solid #CFCFCF;
  box-sizing: border-box;
  padding: 15px 10px 0;
  >h2{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    margin: 0;
    margin-bottom: 18px;
  }
  >div{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    &:nth-child(2n - 1){
      margin-bottom: 16px;
    }
    &:nth-child(2n){
      margin-bottom: 16px;
    }
  }
}
.record{
  background-color: #fff;
  height:50px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #888888;
  text-align: center;
  margin-top: 12px;
}
}
</style>
