<template>
  <div>
    <div class="const">
      <div class="left">
        <img src="../assets/image/logo.png" alt="" style="margin-top: 4%" />
        <img
          src="../assets/image/logoText.png"
          alt=""
          style="margin-bottom: 4%"
        />
      </div>
      <div class="right">
        <router-link to="/home" class="jump">主页</router-link>
        <div @mouseenter="listShow" @mouseleave="listShow">
          产品与服务
          <i class="icon"></i>
        </div>
        <router-link to="/about" class="jump">关于我们</router-link>
      </div>
    </div>
    <div
      class="navigate"
      :style="show ? 'height:80px;' : 'height:0px'"
      @mouseenter="listShow"
      @mouseleave="listShow"
    >
      <div
        class="navigate-item"
        v-for="(item, index) in list"
        :key="index"
        @click="currentClick(index)"
      >
        <div class="navigate-text">
          <router-link
            :to="item.to"
            class="text"
            :class="select == index ? 'select' : ''"
          >
            {{ item.name }}</router-link
          >
        </div>
      </div>
      <!-- <div class="navigate-item">
        <div class="navigate-text">
   <a href="http://www.saixueba.com/" target="_blank" class="text"
            >教育服务</a
          > 
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      show: false,
      list: [
        {
          name: "智慧小区",
          to: "/residentialQuarters",
        },

        {
          name: "智慧楼宇",
          to: "/building",
        },
        {
          name: "智慧园区",
          to: "/parkas",
        },
        {
          name: "智慧交通",
          to: "/park",
        },
        {
          name: "智慧警务",
          to: "/fbi",
        },
        {
          name: "数字乡村",
          to: "/rural",
        },
        {
          name: "智慧城市",
          to: "/city",
        },
        {
          name: "政务大数据",
          to: "/governmentBigData",
        },
        // {
        //   name: "教育服务",
        //   to: "",
        // },
        // {
        //   name: "基层治理",
        //   to: "/theBasicLevel",
        // },
        {
          name: "智慧水务",
          to: "/waterAffairs",
        },
      ],
      select: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    listShow() {
      this.show = !this.show;
    },
    currentClick(id) {
      this.select = id;
      // this.show = !this.show;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.const {
  width: 100%;
  height: 90px;
  line-height: 90px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  background: #10044a;
  padding: 0 160px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;

  .right {
    display: flex;
    div {
      margin-left: 80px;
    }
    .jump {
      color: #fff;
      text-decoration: none;
      margin-left: 80px;
    }
  }
  .icon {
    display: inline-block;
    width: 12px;
    height: 8px;
    background: url("../assets/image/xia.png") no-repeat;
  }
}
.navigate {
  position: absolute;
  background: #fff;
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 10%;
  box-sizing: border-box;
  transition: 0.5s height;
  overflow: hidden;
  z-index: 100;
  .navigate-item {
    font-style: 16px;
    color: #333333;
    margin: 2% 0 0 5%;
  }
  .navigate-text {
    cursor: pointer;
    .text {
      font-size: 16px;
      color: #333333;
      text-decoration: none;
      padding: 10px 0;
      &:hover {
        color: #1864e3;
      }
    }
    .select {
      color: #1864e3;
      border-bottom: 2px solid #1864e3;
    }
  }
}
</style>
