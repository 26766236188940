import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAMap from "vue-amap"; //vue 高德地图
import "./styles/index.less";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Vant from "vant";
import "vant/lib/index.css";
import "amfe-flexible";
Vue.use(Vant)
Vue.use(VueAMap);
Vue.use(ElementUI);
// import VConsole from "vconsole";
// const vConsole = new VConsole();
// Vue.use(vConsole);
Vue.config.productionTip = false;
VueAMap.initAMapApiLoader({
  key: "bdb8280440852cc851665bf341892de8",
  v: "1.4.15",
});
// router.afterEach((to, from, next) => {
//   document.body.scrollTop = 0;
//   document.documentElement.scrollTop = 0;
// });

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
