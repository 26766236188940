<template>
  <div id="app">
    <Title v-if="flag == 2" />
    <router-view />
    <BackToTop v-if="flag == 2" />
    <Tail v-if="flag == 2" />
    <bottomSide v-if="flag == 1"></bottomSide>
    <contact v-if="flag == 1"></contact>
  </div>
</template>

<script>
import Title from "@/components/title";
import Tail from "@/components/tail";
import BackToTop from "@/components/backToTop.vue";
import bottomSide from "@/components/bottom.vue";
import contact from "@/components/contact.vue";
export default {
  name: "App",
  data() {
    return {
      flag: "",
    };
  },
  watch: {
    flag: {
      immediate: true,
      handler(val, old) {
        console.log(val, old);
      },
      deep: true,
    },
  },
  methods: {
    // 判断类型
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      console.log(1);
      this.flag = 1;
      this.$router.replace("/m_homeindex");
    } else {
      console.log(2);
      this.flag = 2;
      this.$router.replace("/home");
    }
  },
  components: { Title, Tail, BackToTop, bottomSide, contact },
};
</script>

<style>
.van-image__img,
.van-image-preview__image {
  background: #fff !important;
}
* {
  padding: 0;
  margin: 0;
}
body {
  background-color: #f6f6f6;
}
.app {
  width: 100%;
}
body,
html {
  width: 100%;
}
/* @media only screen and (max-width: 375px) {
  html {
    font-size: 1px;
  }
} */
</style>
